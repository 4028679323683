






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';
import DatePicker from '@/components/form/DatePicker.vue';

interface CompanyResponse {
  name: string;
  street: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
}

@Component({
  components: { DatePicker }
})
export default class Stepper1 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;

  value: ReportOfConservatorshipManagement | null = null;
}
