








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import {
  PropertyUsage,
  ReportFinances,
  ReportOfConservatorshipManagement
} from '@/modules/reportOfConservatorshipManagement/types';
import uuid from 'uuid/v4';
import clone from 'clone';

@Component({})
export default class Stepper7 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;
  @Prop({ type: Object }) finances!: ReportFinances;

  syncAccounts(finances: ReportFinances) {
    const order: { [type: string]: number } = { cash_account: 0, bank_account: 1, credit_account: 2 };

    this.report.economic_circumstances.balance_first_of_january.payables.amount = parseFloat(finances.payables);
    this.report.economic_circumstances.balance_first_of_january.finance_accounts = clone(finances.accounts, false)
      .sort((first, second) => {
        return order[first.category] - order[second.category];
      })
      .map((account) => ({
        ...account,
        balance: parseFloat(account.balance)
      }));
  }

  syncProperties(finances: ReportFinances) {
    this.report.economic_circumstances.balance_first_of_january.properties = finances.assets
      .filter((asset) => {
        return asset.type === 'property';
      })
      .map((asset) => ({
        id: asset.id,
        name: asset.name,
        balance: asset.balance,
        boundary: '',
        bl: '',
        bd: '',
        usage: 'owner_occupation' as PropertyUsage
      }));
  }

  removeProperty({ id }: { id: string }) {
    this.report.economic_circumstances.balance_first_of_january.properties = [
      ...this.report.economic_circumstances.balance_first_of_january.properties.filter((payable) => payable.id !== id)
    ];
  }

  addProperty() {
    this.report.economic_circumstances.balance_first_of_january.properties = [
      ...this.report.economic_circumstances.balance_first_of_january.properties,
      { id: uuid(), name: '', balance: 0, boundary: '', bl: '', bd: '', usage: 'owner_occupation' as PropertyUsage }
    ];
  }

  removeAccount({ id }: { id: string }) {
    this.report.economic_circumstances.balance_first_of_january.finance_accounts = [
      ...this.report.economic_circumstances.balance_first_of_january.finance_accounts.filter((payable) => payable.id !== id)
    ];
  }

  addAccount() {
    this.report.economic_circumstances.balance_first_of_january.finance_accounts = [
      ...this.report.economic_circumstances.balance_first_of_january.finance_accounts,
      { id: uuid(), name: '', balance: 0, number: '', category: 'cash_account' }
    ];
  }
}
