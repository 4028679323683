





























































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  ReportOfConservatorshipManagement,
  UpdateReportOfConservatorshipManagement
} from '@/modules/reportOfConservatorshipManagement/types';
import clone from 'clone';
import { CLEAR_UPDATED, UPDATE_REPORT } from '@/modules/reportOfConservatorshipManagement/store';
import { ApiResponse } from '@/components/types';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import format from '@/filter/dateFormat';

const Report = namespace('reportOfConservatorshipManagement');

@Component({})
export default class Stepper9 extends Vue {
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Boolean, required: true }) active!: boolean;

  @Report.Action(UPDATE_REPORT) applyReport!: (command: UpdateReportOfConservatorshipManagement) => ApiResponse;
  @Report.Mutation(CLEAR_UPDATED) clearCreated!: ({ conservatorship_id }: { conservatorship_id: string }) => void;

  finalReport: ReportOfConservatorshipManagement = clone(this.report, false);

  @Watch('active', { immediate: true })
  onChange(active: boolean) {
    if (!active) return;
    this.finalReport = clone(this.report, false);

    this.finalReport.personal_circumstances.assets.end_balance = this.parseFloat(this.finalReport.personal_circumstances.assets.end_balance.toString());
    this.finalReport.personal_circumstances.assets.first_of_january_balance = this.parseFloat(this.finalReport.personal_circumstances.assets.first_of_january_balance.toString());

    this.finalReport.economic_circumstances.placement_cost.balance = this.parseFloat(this.finalReport.economic_circumstances.placement_cost.balance.toString());
    this.finalReport.economic_circumstances.placement_cost.allowance_amount = this.parseFloat(this.finalReport.economic_circumstances.placement_cost.allowance_amount.toString());

    if (!this.finalReport.economic_circumstances.placement_cost.date) {
      this.finalReport.economic_circumstances.placement_cost.date = format(Date.now(), 'yyyy-MM-dd');
    }

    this.finalReport.economic_circumstances.end_balance.payables.amount = this.parseFloat(this.finalReport.economic_circumstances.end_balance.payables.amount.toString());
    this.finalReport.economic_circumstances.balance_first_of_january.payables.amount = this.parseFloat(this.finalReport.economic_circumstances.balance_first_of_january.payables.amount.toString());

    this.finalReport.economic_circumstances.revenue.assets = this.finalReport.economic_circumstances.revenue.assets
      .map(({ balance, ...asset }) => ({
        ...asset,
        balance: this.parseFloat(balance.toString())
      }))
      .filter((asset) => this.isValid(asset.balance, asset.name));

    this.finalReport.economic_circumstances.revenue_usage.subsistence_parts = this.finalReport.economic_circumstances.revenue_usage.subsistence_parts
      .map(({ cost, ...asset }) => ({
        ...asset,
        cost: this.parseFloat(cost.toString())
      }))
      .filter((asset) => this.isValid(asset.cost, asset.name));

    this.finalReport.economic_circumstances.revenue_usage.other_parts = this.finalReport.economic_circumstances.revenue_usage.other_parts
      .map(({ cost, ...asset }) => ({
        ...asset,
        cost: this.parseFloat(cost.toString())
      }))
      .filter((asset) => this.isValid(asset.cost, asset.name));

    this.finalReport.economic_circumstances.revenue_usage.payables = this.finalReport.economic_circumstances.revenue_usage.payables
      .map(({ cost, ...asset }) => ({
        ...asset,
        cost: this.parseFloat(cost.toString())
      }))
      .filter((asset) => this.isValid(asset.cost, asset.name));

    this.finalReport.economic_circumstances.new_assets = this.finalReport.economic_circumstances.new_assets
      .filter((asset) => !!asset);

    this.finalReport.economic_circumstances.end_balance.properties = this.finalReport.economic_circumstances.end_balance.properties
      .map(({ balance, ...asset }) => ({
        ...asset,
        balance: this.parseFloat(balance.toString())
      }))
      .filter((property) => this.isValid(property.balance, property.name));

    this.finalReport.economic_circumstances.end_balance.finance_accounts = this.finalReport.economic_circumstances.end_balance.finance_accounts
      .map(({ balance, ...account }) => ({
        ...account,
        balance: this.parseFloat(balance.toString())
      }))
      .filter((account) => this.isValid(account.balance, account.name, true));

    this.finalReport.economic_circumstances.balance_first_of_january.properties = this.finalReport.economic_circumstances.balance_first_of_january.properties
      .map(({ balance, ...asset }) => ({
        ...asset,
        balance: this.parseFloat(balance.toString())
      }))
      .filter((property) => this.isValid(property.balance, property.name));

    this.finalReport.economic_circumstances.balance_first_of_january.finance_accounts = this.finalReport.economic_circumstances.balance_first_of_january.finance_accounts
      .map(({ balance, ...asset }) => ({
        ...asset,
        balance: this.parseFloat(balance.toString())
      }))
      .filter((account) => this.isValid(account.balance, account.name, true));

    this.finalReport.economic_circumstances.legal_proceedings = this.finalReport.economic_circumstances.legal_proceedings
      .filter((legalProceeding) => !!legalProceeding);
  }

  get placementAddress() {
    return this.finalReport.personal_circumstances.placement.address;
  }

  get placementCost() {
    return this.finalReport.economic_circumstances.placement_cost;
  }

  get additional() {
    return this.finalReport.economic_circumstances.additional;
  }

  isValid(price: number, name: string, exceptEmpty: boolean = false): boolean {
    return !isNaN(price) && (exceptEmpty || price !== 0) && !!name;
  }

  parseFloat(number: string) {
    const parsed = parseFloat((number));

    if (!isNaN(parsed)) {
      return parsed;
    }

    return 0;
  }

  save() {
    return this.applyReport({
      conservatorship_id: this.conservatorship.id,
      report: this.finalReport
    });
  }

  success() {
    this.clearCreated({ conservatorship_id: this.conservatorship.id });

    this.$router.push({
      name: 'conservatorship-details', params: {
        id: this.conservatorship.id
      },
      query: { active: 'tab-reports' }
    }).catch(() => {
    });
  }
}
