























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';
import { Contact } from '@/modules/contact/types';
import DoctorSelect from '@/modules/conservatorshipToContact/components/DoctorSelect.vue';

@Component({
  components: { DoctorSelect }
})
export default class Stepper2 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;

  doctors: Contact[] = [];

  selectDoctor(doctor: Contact) {
    this.report.personal_circumstances.health_progress.doctor.name = doctor.name || '';
    this.report.personal_circumstances.health_progress.doctor.additional = doctor.phoneNumber || '';
    this.report.personal_circumstances.health_progress.doctor.street = doctor.street || '';
    this.report.personal_circumstances.health_progress.doctor.city = doctor.city || '';
    this.report.personal_circumstances.health_progress.doctor.zip_code = doctor.zipCode || '';
  }
}
